import { useState } from "react";
import { Document, FilterQueryParams } from "../../../types";
import { Button } from "primereact/button";
import { FilePreviewPopup } from "../../FilePreviewPopup/FilePreviewPopup";
import { classNames } from "primereact/utils";
import { useQueryClient } from "@tanstack/react-query";
import { DocumentService } from "../../../DocumentService";

type PropTypes = {
  document: Document;
};

export const PreviewDocBodyTemplate = ({ document }: PropTypes) => {
  const [orderNumber, setOrderNumber] = useState("");
  const queryClient = useQueryClient();

  const handleClear = async () => {
    await queryClient.invalidateQueries({
      queryKey: DocumentService.documents.queryKey({} as FilterQueryParams),
    });
    setOrderNumber("");
  };

  return (
    <div>
      <Button
        disabled={!document.trackingNumber}
        className={classNames("p-button-rounded p-button-text")}
        onClick={() => setOrderNumber(document.orderNumber)}
        icon="pi pi-file-pdf"
      />
      {orderNumber && (
        <FilePreviewPopup orderNumber={orderNumber} onHide={handleClear} />
      )}
    </div>
  );
};
