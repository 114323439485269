import { useDocuments } from "../hooks/useDocuments";
import styles from "./documentsList.module.scss";
import Loading from "../../../../components/Loading/Loading";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { rowOptions } from "../../../../constants";
import { PreviewDocBodyTemplate } from "./ColumnTemplates/PreviewDocBodyTemplate";
import { RequestedDateBodyTemplate } from "./ColumnTemplates/RequestedDateBodyTemplate";
import { UspsTrackingNumberBodyTemplate } from "./ColumnTemplates/UspsTrackingNumberBodyTemplate";
import { DeleteDocBodyTemplate } from "./ColumnTemplates/DeleteDocBodyTemplate";
import { UploadDocBodyTemplate } from "./ColumnTemplates/UploadDocBodyTemplate";
import { StatusBodyTemplate } from "./ColumnTemplates/StatusBodyTemplate";
import { Filters } from "./Filters/Filters";
import sharedStyles from "../../../../assets/sharedStyles.module.scss";
import BooleanBodyTemplate from "./ColumnTemplates/BooleanBodyTemplate";
import useBulkSelect from "../../../../hooks/useBulkSelect";
import FirstColumnTemplate from "./ColumnTemplates/FirstColumnTemplate";
import {useMutation} from "@tanstack/react-query";
import {AxiosError} from "axios";
import {extractErrorMessage} from "../../../../helpers/extractErrorMessage";
import {DocumentService} from "../../DocumentService";
import {useToast} from "../../../../hooks/useToast";
import DownloadLabelBodyTemplate from "./ColumnTemplates/DownloadLabelBodyTemplate";
import {successToastLife} from "../../../../config/config";
import {Document} from "../../types";

export const DocumentsList = () => {
  const { show } = useToast();
  const {
    documents,
    isLoading,
    maxCount,
    filters,
    setFilters,
    setQuery,
    onPage,
  } = useDocuments();
  const {
    bulkSelectMode,
    bulkSelectedIds,
    onBulkSelect,
    toggleBulkMode,
    resetBulkState,
    updateSelectedIds,
  } = useBulkSelect();
  const getNamesByStatusData = useMutation({
    mutationFn: DocumentService.generateFedexLabels,
    onSuccess: () => {
      resetBulkState();
      show({
        severity: 'success',
        detail: 'The exported file has been emailed to you!',
        life: successToastLife,
      });
    },
    onError: (error: AxiosError<{ message: string }>, variables) => {
      resetBulkState();
      const errorData = {
        error: error,
        variables: variables,
        retryFn: getNamesByStatusData.mutate,
        show: show,
      };
      extractErrorMessage(errorData);
    },
  });
  const labelsExportingLoading = getNamesByStatusData.isPending;
  const generateFedexLabels = () => {
    getNamesByStatusData.mutate({ ...filters, ids: bulkSelectedIds });
  }
  console.log(documents, 'documents');
  const currentDocumentsCount = documents?.length || [];
  console.log(documents, 'documents');
  const loadedDocumentsIds = (documents || []).map((item: Document) => item.id);
  return (
    <div className={styles.container}>
      <Filters
          filters={filters}
          setFilters={setFilters}
          setQuery={setQuery}
          toggleBulkMode={toggleBulkMode}
          bulkSelectMode={bulkSelectMode}
          bulkSelectedIds={bulkSelectedIds}
          generateFedexLabels={generateFedexLabels}
          labelsExportingLoading={labelsExportingLoading}
          currentDocumentsCount={currentDocumentsCount}
          updateSelectedIds={updateSelectedIds}
          loadedDocumentsIds={loadedDocumentsIds}
      />
      {isLoading && !documents ? (
        <div className={styles.loadingWrapper}>
          <Loading />
        </div>
      ) : (
        <DataTable
          paginator={maxCount / 10 > 1}
          rowsPerPageOptions={rowOptions}
          emptyMessage={() => {
            return (
                <div className={styles.emptyMessageTemplate}>
                  <p className={sharedStyles.noTableRecords}>
                    There are no documents added yet
                  </p>
                </div>
          )
          }}
          onPage={onPage}
          totalRecords={maxCount}
          first={filters.page * filters.size}
          rows={filters.size}
          value={documents}
          lazy
        >
          <Column
            className={styles.column}
            field="purchaserName"
            header="Taxpayer Name"
            body={(rowData) => (
                <FirstColumnTemplate
                    document={rowData}
                    bulkSelectMode={bulkSelectMode}
                    onBulkSelect={onBulkSelect}
                    bulkSelectedIds={bulkSelectedIds}
                />
            )}
          ></Column>
          <Column
            className={styles.column}
            field="purchaserEmail"
            header="Taxpayer Email"
          ></Column>
          <Column
            className={styles.column}
            field="orderNumber"
            header="Order Number"
          ></Column>
          <Column
            className={styles.column}
            body={(rowData) => (
              <UspsTrackingNumberBodyTemplate document={rowData} />
            )}
            header="Tracking Number"
          ></Column>
          <Column
            className={styles.column}
            body={(rowData) => <RequestedDateBodyTemplate document={rowData} />}
            header="Requested Date"
          ></Column>
          <Column
            className={styles.column}
            body={(rowData) => <PreviewDocBodyTemplate document={rowData} />}
            header="Preview doc"
          ></Column>
          <Column
              className={styles.column}
              body={(rowData) => {
                return (
                    <BooleanBodyTemplate isTrue={Boolean(rowData.paidDateTime)}/>
                )
              }}
              header="Paid"
          ></Column>
          <Column
              className={styles.column}
              body={(rowData) => {
                return (
                    <BooleanBodyTemplate isTrue={Boolean(rowData.documentViewed)}/>
                )
              }}
              header="Viewed"
          ></Column>
          <Column
              className={styles.column}
              body={(rowData) => {
                return (
                    <BooleanBodyTemplate isTrue={Boolean(rowData.statuses.FULLY_SIGNED)}/>
                )
              }}
              header="Signed"
          ></Column>
          <Column
            className={styles.column}
            body={(rowData) => <StatusBodyTemplate document={rowData} />}
            header="Status"
          ></Column>
          <Column
              className={styles.column}
              body={(rowData) => <DownloadLabelBodyTemplate documentData={rowData} />}
              header="FEDEX label"
          ></Column>
          <Column
            className={styles.column}
            body={(rowData) => <UploadDocBodyTemplate document={rowData} />}
            header="Upload doc"
          ></Column>
          <Column
            className={styles.column}
            body={(rowData) => <DeleteDocBodyTemplate document={rowData} />}
            header="Delete doc"
          ></Column>
        </DataTable>
      )}
    </div>
  );
};
